import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb el capell normalment viscós, que en temps humit torna mucilaginós. El color varia de taronja terrós al centre, a taronja groguenc o viu al marge i té un diàmetre de fins a 12 cm. És gruixut, convex i amb el marge lleugerament enrotllat que passa a pla i un poc deprimit, tornant ondulat. Les làmines, atapeïdes, són de color blanc tornant-se rogenques. El peu és cilíndric, sovint una mica corbat, amb esquames roig taronja, més terroses a la base i blanquinoses a la part superior. Quan hi ha molta humitat presenta gotes color taronja sobre la superfície. Les espores són blanques en massa, globoses, petites, de 4-5 x 2-4 micras.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      